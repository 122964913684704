import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { Connection, ConnectionType } from '~/data/types';
import { State } from '~/data/types/store';
import { useUserSubscription } from '~/data/subscription';

export const useConnections = () => {
  const { canAddFitnessOnlyConnection } = useUserSubscription();
  const isLoading = computed(() => store.state.connections.isLoading);
  const store = useStore<State>();
  const connections = computed<Connection[]>(() => store.state.connections?.connections || []);

  const hasConnectionWithStats = computed(() => {
    const isConnectedToGarmin = getConnection(ConnectionType.Garmin);
    const isConnectedToStrava = getConnection(ConnectionType.Strava);
    const isConnectedToWahoo = getConnection(ConnectionType.Wahoo);
    return isConnectedToGarmin || isConnectedToStrava || isConnectedToWahoo;
  });

  const canAddConnection = (type: ConnectionType) => {
    if (canAddFitnessOnlyConnection.value) {
      return true;
    }

    const fitnessOnlyConnections = [ConnectionType.TrainingPeaks, ConnectionType.Garmin, ConnectionType.Wahoo];
    return !fitnessOnlyConnections.includes(type);
  };

  const setConnectionIntent = async(type: ConnectionType) => {
    localStorage.setItem('connectionType', type.toString());

    if (type === ConnectionType.Garmin) {
      const tokens = await store.dispatch('connections/GET_GARMIN_TOKENS');
      if (tokens.oauth_token) {
        const redirectUrl = `${window?.location?.origin + window?.location?.pathname}`;
        localStorage.setItem('garmin_tokens', JSON.stringify(tokens));
        window.location.href = `https://connect.garmin.com/oauthConfirm?oauth_token=${tokens.oauth_token}&oauth_callback=${redirectUrl}`;
      }
    }
  };

  const getConnection = (type: ConnectionType): Connection | null => {
    const items = connections.value.filter(connection => connection.type === type);
    return items.length ? items[0] : null;
  };

  const getGarminTokens = (query: { [key: string]: any }) => {
    const oauthToken = query.oauth_token;
    const oauthVerifier = query.oauth_verifier;
    if (oauthToken && oauthVerifier) {
      const garminRequestTokens = JSON.parse(localStorage?.getItem('garmin_tokens') || '') || '';
      localStorage?.removeItem('garmin_tokens');
      return {
        request_token: oauthToken,
        request_token_secret: garminRequestTokens.oauth_token_secret,
        verifier: oauthVerifier,
      };
    }
  };

  const connect = async(type: ConnectionType, query: { [key: string]: any }, redirectUrl: string): Promise<boolean> => {
    const storedType = Number(localStorage?.getItem('connectionType') || null);
    if (!storedType || storedType !== type) {
      return false;
    }

    const isGarmin = type === ConnectionType.Garmin;
    const code = query.code;
    const garminTokens = isGarmin ? getGarminTokens(query) : null;
    if (!code && !garminTokens) {
      return false;
    }

    const params = {
      platform_type: type,
      redirect_uri: redirectUrl,
      authorization_code: code,
    };
    if (isGarmin) {
      Object.assign(params, garminTokens);
      delete params.authorization_code;
    }

    localStorage?.removeItem('connectionType');

    try {
      await store.dispatch('connections/SUBMIT_CONNECTION', params);
      return true;
    } catch {
      // Do nothing
    }

    return false;
  };

  const connectWithApple = async(code: string, redirectUri: string) => {
    const params = {
      platform: 'web',
      platform_type: ConnectionType.Apple,
      redirect_uri: redirectUri,
      authorization_code: code,
    };

    try {
      await store.dispatch('connections/SUBMIT_CONNECTION', params);
      return true;
    } catch {
      // Do nothing
    }
  };

  const connectWithFacebook = async(accountId: number, accessToken: string, signedRequest: string) => {
    const params = {
      platform: 'web',
      platform_type: ConnectionType.Facebook,
      access_token: accessToken,
      account_id: accountId,
      signed_request: signedRequest,
    };

    try {
      await store.dispatch('connections/SUBMIT_CONNECTION', params);
      return true;
    } catch {
      // Do nothing
    }
  };

  const disconnect = async(type: ConnectionType): Promise<boolean> => {
    const id = connections.value.find(connection => connection.type === type)?.id;
    if (!id) {
      return true;
    }

    try {
      await store.dispatch('connections/DELETE_CONNECTION', id);
    } catch {
      // Do nothing
    }

    return true;
  };

  const getRedirectUrl = (type: ConnectionType, redirectUrl: string): string | null => {
    switch (type) {
      case ConnectionType.Strava:
        return `https://www.strava.com/oauth/authorize?client_id=${process.env.STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${redirectUrl}&approval_prompt=force&scope=profile:read_all,activity:read_all`;
      case ConnectionType.TrainingPeaks:
        return `${process.env.TRAINING_PEAKS_AUTHORIZATION_URL}/OAuth/Authorize?response_type=code&client_id=${process.env.TRAINING_PEAKS_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=events%3Awrite%20workouts%20workouts%3Adetails%20athlete%3Aprofile`;
      case ConnectionType.Wahoo:
        return `https://api.wahooligan.com/oauth/authorize?client_id=${process.env.WAHOO_CLIENT_ID}&response_type=code&redirect_uri=${redirectUrl}&scope=workouts_read%20user_read%20offline_data%20power_zones_read`;
      default:
        return null;
    }
  };

  const getConnections = async() => {
    if (!connections.value.length) {
      await store.dispatch('connections/GET_CONNECTION');
    }
  };

  return {
    canAddConnection,
    canAddFitnessOnlyConnection,
    connect,
    connections,
    connectWithApple,
    connectWithFacebook,
    disconnect,
    getConnection,
    getConnections,
    getRedirectUrl,
    hasConnectionWithStats,
    isLoading,
    setConnectionIntent,
  };
};
