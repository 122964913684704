export enum CommunityGroupType {
    All = 'all',
    Friends = 'friends',
    Age = 'age',
    Event = 'event',
    Experience = 'experience',
    Location = 'location',
    Help = 'help',
};

export type CommunityGroupItem = {
  id: number;
  type: CommunityGroupType;
  slug: string;
  title: string;
  description: string;
  unread_count: number;
};
