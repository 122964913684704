import { useStore, computed } from '@nuxtjs/composition-api';
import {
  CommunityGroupItem,
  CommunityGroupType,
} from '../types/community-group';
import { State } from '~/data/types/store';

export const useCommunityGroup = () => {
  const store = useStore<State>();
  const isLoading = computed<boolean>(
    () => store.state.communityGroups.isLoading,
  );

  const communityGroups = computed<CommunityGroupItem[]>(
    () => store.state.communityGroups.list,
  );

  const unreadTotalPostsCount = computed<number>(
    () => store.state.auth.user?.data?.unread_posts_count ?? 0,
  );

  const friendsGroup = computed<CommunityGroupItem | undefined>(() =>
    store.state.communityGroups.list.find(
      i => i.type === CommunityGroupType.Friends,
    ),
  );

  const getCommunityGroups = async() => {
    await store.dispatch('communityGroups/GET_LIST');
  };

  const setUnreadCountForGroup = async(groupId: number, count: number) => {
    await store.dispatch('communityGroups/SET_UNREAD_COUNT_FOR_GROUP', {
      groupId,
      count,
    });
  };

  const getFriendsGroup = async() => {
    if (!friendsGroup.value) {
      await getCommunityGroups();
    }
    return friendsGroup.value;
  };

  return {
    isLoading,
    communityGroups,
    getCommunityGroups,
    setUnreadCountForGroup,
    unreadTotalPostsCount,
    getFriendsGroup,
    friendsGroup,
  };
};
