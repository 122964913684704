import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { State } from '~/data/types/store';

enum FeatureFlag {
  DashboardV2 = 'release-dashboard-v2',
  ShareableMoments = 'release-shareable-moments-training-summary',
  ShareableMomentsShareWorkout = 'release-shareable-moments-workout',
  EditableWorkoutStats = 'release-editable-workout-stats',
}

export const useFeatureFlag = () => {
  const store = useStore<State>();
  const hasAccessToDashboardV2 = computed(() => true); // TODO: Remove DashboardV2
  const hasAccessToShareableMoments = computed(() => hasAccess(FeatureFlag.ShareableMoments));
  const hasAccessToShareWorkout = computed(() => hasAccess(FeatureFlag.ShareableMomentsShareWorkout));
  const hasAccessToEditableStats = computed(() => hasAccess(FeatureFlag.EditableWorkoutStats));

  const hasAccess = (feature: FeatureFlag) => store.state.auth.user?.data?.enabled_features.includes(feature) ?? false;

  return {
    hasAccessToDashboardV2,
    hasAccessToEditableStats,
    hasAccessToShareableMoments,
    hasAccessToShareWorkout,
  };
};
