import { SubscriptionPlanType } from './subscription';
import { UserFollowee } from './users';

export enum Gender {
  Male = 1,
  Female = 2,
};

export enum MeasurementSystem {
  Metric = 1,
  Imperial = 2,
};

export enum ExperienceLevel {
  Beginner = 'beginner',
  Intermediate = 'intermediate',
  Advanced = 'advanced',
};

export type UserNotificationSettings = {
  isCommentNotificationOn: boolean,
  isFiverNotificationOn: boolean,
  isIncompleteWorkoutNotificationOn: boolean,
  isScheduledWorkoutNotificationOn: boolean,
};

export type UserPrivacySettings = {
  isAutoShareGraphicsWithGroupsOn: boolean;
  public: boolean;
};

export type UserSettings = UserNotificationSettings & UserPrivacySettings;

export type UserProfile = {
  age_group: string | null,
  avatar: string | null,
  experience_level: ExperienceLevel | null,
  first_name: string | null,
  followed: number,
  followers: number,
  is_following: boolean,
  last_name: string | null,
  month_of_birth: number | null,
  mottiv: string | null,
  own_followee: UserFollowee | null,
  year_of_birth: number | null,
  primary_sport: {
    id: number,
    title: string,
    disciplines: string[],
  } | null,
  is_private: boolean,
};

export enum UserSubscriptionStatus {
  Active = 'active',
  Trialing = 'trialing',
  Cancelled = 'cancelled',
  PastDue = 'past_due',
};

export enum UserSubscriptionType {
  Stripe = 1,
  AppleIAP = 2,
};

export type UserSubscription = {
  is_premium: boolean,
  next_payment_date: string,
  payment_amount: number | null,
  payment_currency: string | null,
  payment_interval: string | null,
  status: UserSubscriptionStatus,
  stripe_plan_badge_content: string | null,
  stripe_plan_id: string | null,
  stripe_plan_summary: string | null,
  stripe_plan_title: string | null,
  stripe_scheduled_plan_id: string | null,
  stripe_scheduled_plan_title: string | null,
  stripe_scheduled_plan_is_premium: boolean | null,
  stripe_scheduled_plan_payment_amount: number | null,
  stripe_scheduled_plan_type: SubscriptionPlanType | null,
  type: UserSubscriptionType,
};

// Properties must match what's returned from the API as they're stored directly in $auth.user.data
export type User = {
  avatar: string | null,
  analytics_id: string | null,
  card_brand: string | null,
  card_last_four: string | null,
  city: string,
  country_code: string,
  country: string,
  created_at: string,
  email: string,
  enabled_features: string[],
  first_name: string,
  gender: Gender | null,
  has_beta_access: boolean | null,
  has_current_password: boolean,
  id: number,
  is_email_confirmed: boolean,
  last_name: string,
  measurement_system: MeasurementSystem,
  subscription: UserSubscription | null,
  timezone: string,
  trial_ends_at: string,
  unread_notifications_count: number,
  unread_posts_count: number,
  weight: number | null,
  usersFollowedCount: number,
  followersCount: number,
};
